import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from '@repo/ui/components/AlertDialog.js'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle
} from '@repo/ui/components/Dialog.js'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@repo/ui/components/DropdownMenu.js'
import { Icon } from '@repo/ui/components/Icon.js'
import { Input } from '@repo/ui/components/Input.js'
import { Label } from '@repo/ui/components/Label.js'
import {
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@repo/ui/components/Select.js'
import { Spacer } from '@repo/ui/components/Spacer.js'
import { useState } from 'react'
import {
  Form,
  FormField,
  FormFieldError,
  FormFieldLabel,
  FormInput,
  FormSelect,
  FormSubmitButton,
  FormTextarea
} from '~/components/Form'
import { useForm } from '~/hooks/useForm'
import {
  DeleteSessionSchema,
  ReportSessionIssueSchema,
  type Session
} from './routeConfig'

export const SessionActions = ({ session }: { session: Session }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false)

  const reportIssueForm = useForm({
    schema: ReportSessionIssueSchema,
    include: {
      sessionId: session.id,
      vendorName: session.vendor.name,
      productName: session.product.name
    },
    action: '/?index',
    onSuccess: () => {
      setDialogOpen(false)
      setMenuOpen(false)
    }
  })

  const deleteForm = useForm({
    schema: DeleteSessionSchema,
    include: { sessionId: session.id },
    action: '/?index'
  })
  return (
    <>
      <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
        <DropdownMenuTrigger>
          <Icon name="dots-vertical" size="sm" />
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem onSelect={() => setDialogOpen(true)} icon="warning">
            Report issue
          </DropdownMenuItem>

          <DropdownMenuItem
            onSelect={() => setDeleteAlertOpen(true)}
            icon="trash-can"
          >
            Delete session
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Report an issue</DialogTitle>
            <DialogDescription>
              Let us know if something doesn't look right with this session.
            </DialogDescription>
          </DialogHeader>

          <Form {...reportIssueForm}>
            <div className="grid grid-cols-2 gap-4">
              <FormField name={reportIssueForm.keys.vendorName}>
                <FormFieldLabel>Vendor</FormFieldLabel>
                <FormInput disabled value={session.vendor.name} />
              </FormField>
              <FormField name={reportIssueForm.keys.productName}>
                <FormFieldLabel>Product</FormFieldLabel>
                <FormInput disabled value={session.product.name} />
              </FormField>
              <div className="col-span-2 space-y-4">
                <fieldset className="space-y-1">
                  <Label>User</Label>
                  <Input disabled value={session.user.displayName} />
                </fieldset>

                <FormField name={reportIssueForm.keys.category}>
                  <FormFieldLabel>Category</FormFieldLabel>
                  <FormSelect required>
                    <SelectTrigger>
                      <SelectValue placeholder="Select an option..." />
                    </SelectTrigger>
                    <SelectContent>
                      {ReportSessionIssueSchema.shape.category.options.map(
                        (o) => (
                          <SelectItem key={o} value={o}>
                            {o}
                          </SelectItem>
                        )
                      )}
                    </SelectContent>
                  </FormSelect>
                  <FormFieldError />
                </FormField>

                <FormField name={reportIssueForm.keys.notes}>
                  <FormFieldLabel>Notes</FormFieldLabel>
                  <FormTextarea rows={2} className="min-h-0" />
                  <FormFieldError />
                </FormField>
              </div>
            </div>

            <Spacer size="sm" />

            <FormSubmitButton>Submit</FormSubmitButton>
          </Form>
        </DialogContent>
      </Dialog>

      <AlertDialog open={deleteAlertOpen} onOpenChange={setDeleteAlertOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Delete session?</AlertDialogTitle>
            <AlertDialogDescription />
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel />
            <Form {...deleteForm}>
              <FormSubmitButton variant="destructive">Delete</FormSubmitButton>
            </Form>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}
