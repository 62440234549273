import type { ActionFunctionArgs, LoaderFunctionArgs } from '@remix-run/node'
import { useLoaderData } from '@remix-run/react'
import { Spacer } from '@repo/ui/components/Spacer.js'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from '@repo/ui/components/Table.js'
import { cn } from '@repo/ui/utils/utils'
import { z } from 'zod'
import { PageTitle } from '~/components/Page'
import { ProductLink } from '~/components/ProductLink'
import { RateLimitedButton } from '~/components/RateLimitedButton'
import { UserBadge } from '~/components/UserBadge'
import { VendorBadge } from '~/components/VendorBadge'
import { DataTable } from '~/components/data-table/DataTable'
import { DataTableEmptyState } from '~/components/data-table/DataTableEmptyState'
import { DataTableHead } from '~/components/data-table/DataTableHead'
import { DataTablePagination } from '~/components/data-table/DataTablePagination'
import { DataTableSearch } from '~/components/data-table/DataTableSearch'
import { useDataTable } from '~/hooks/useDataTable'
import { useExtensionStatus } from '~/hooks/useExtensionStatus'
import { requireAuth } from '~/services/auth.server'
import { parseQueryParams } from '~/utils/misc'
import {
  mutationFailure,
  mutationSuccess,
  performMutation
} from '~/utils/mutation.server'
import { setToast } from '~/utils/toast.server'
import { validateFormData, validationFailure } from '~/utils/validation.server'
import { FavoriteButton } from '../api.favorites/FavoriteButton'
import { TagEditor } from '../api.vendor-tags/TagEditor'
import { SessionActions } from './SessionActions'
import { SessionStatistics } from './SessionStatistics'
import { deleteSession } from './deleteSession.server'
import { getSessionStatistics } from './getSessionStatistics.server'
import { getSessionsByOrgId } from './getSessionsByOrgId.server'
import { reportIssue } from './reportIssue.server'
import {
  DeleteSessionSchema,
  QueryParamsSchema,
  ReportSessionIssueSchema
} from './routeConfig'
import { useLiveRefresh } from './useLiveRefresh'

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const auth = await requireAuth(request)
  const params = parseQueryParams(request, QueryParamsSchema)
  const [sessions, statistics] = await Promise.all([
    getSessionsByOrgId(auth, params),
    getSessionStatistics(auth, params)
  ])
  const { period, ...tableParams } = params
  return {
    sessions,
    statistics,
    tableParams
  }
}

export const action = async ({ request }: ActionFunctionArgs) => {
  const auth = await requireAuth(request)
  const input = await validateFormData(
    request,
    z.discriminatedUnion('intent', [
      DeleteSessionSchema,
      ReportSessionIssueSchema
    ])
  )
  if (!input.ok) return validationFailure(input)

  switch (input.data.intent) {
    case 'deleteSession': {
      const result = await performMutation({
        input: input.data,
        environment: { auth },
        mutation: deleteSession
      })

      if (!result.ok) return mutationFailure(result)

      return mutationSuccess(result, {
        headers: {
          'set-cookie': await setToast(request, {
            title: 'Session deleted',
            type: 'info'
          })
        }
      })
    }
    case 'reportSessionIssue': {
      const result = await performMutation({
        input: input.data,
        environment: { auth },
        mutation: reportIssue
      })

      if (!result.ok) return mutationFailure(result)

      return mutationSuccess(result, {
        headers: {
          'set-cookie': await setToast(request, {
            title: `Thanks!. We're on it.`,
            type: 'success'
          })
        }
      })
    }
  }
}

export default function IndexRoute() {
  const { sessions, tableParams } = useLoaderData<typeof loader>()

  const { isActive, state } = useExtensionStatus()

  const { newSessionIds, refresh, status, data } = useLiveRefresh({ sessions })

  const table = useDataTable({
    ...tableParams,
    pageCount: sessions.at(0)?.pageCount,
    rowCount: sessions.length
  })

  return (
    <div>
      <div className="flex items-center justify-between gap-8">
        <PageTitle>Sessions</PageTitle>
        <div className="flex items-center gap-2">
          <RateLimitedButton
            variant="outline"
            icon="rotate-cw"
            onClick={refresh}
            loading={status !== 'idle'}
            limitInMs={3000}
          >
            Refresh
          </RateLimitedButton>
        </div>
      </div>
      <Spacer size="sm" />

      <SessionStatistics />

      <Spacer size="md" />

      <DataTable {...table} className="space-y-2">
        <div className="w-full flex items-center justify-between">
          <DataTableSearch placeholder="Search sessions..." className="w-72" />
        </div>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="p-0" />
              <DataTableHead>Vendor</DataTableHead>
              <DataTableHead>Product</DataTableHead>
              <DataTableHead>Tags</DataTableHead>
              <DataTableHead>User</DataTableHead>
              <DataTableHead>Date</DataTableHead>
              <DataTableHead />
            </TableRow>
          </TableHeader>
          <TableBody>
            {data.map((row) => (
              <TableRow
                key={row.id}
                className={cn(
                  'transition-colors duration-1000',
                  newSessionIds.includes(row.id) ? 'bg-success-100' : 'bg-white'
                )}
              >
                <TableCell className="pr-0 pl-2">
                  <div className="flex justify-center items-center text-muted-foreground">
                    <FavoriteButton
                      isFavorite={row.vendor.isFavorite}
                      vendorId={row.vendor.id}
                    />
                  </div>
                </TableCell>
                <TableCell>
                  <VendorBadge
                    vendorId={row.vendor.id}
                    name={row.vendor.name}
                    tagline={row.vendor.tagline}
                    iconUrl={row.vendor.iconUrl}
                  />
                </TableCell>
                <TableCell className="text-muted-foreground">
                  <ProductLink
                    name={row.product.name}
                    productId={row.product.id}
                    vendorId={row.vendor.id}
                    iconUrl={row.product.iconUrl}
                  />
                </TableCell>
                <TableCell>
                  <TagEditor
                    tagIds={row.vendor.tagIds}
                    vendorId={row.vendor.id}
                  />
                </TableCell>
                <TableCell>
                  <UserBadge
                    userId={row.user.id}
                    displayName={row.user.displayName}
                    avatarUrl={row.user.avatarUrl}
                  />
                </TableCell>
                <TableCell>{row.createdAt}</TableCell>
                <TableCell>
                  <SessionActions session={row} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <DataTablePagination />
        <DataTableEmptyState
          title="No sessions yet."
          description={
            state === 'loading' || (state === 'idle' && isActive)
              ? null
              : 'Install the browser extension to get started.'
          }
          illustration="dataProcessing"
        />
      </DataTable>
    </div>
  )
}
